import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import "./localization/i18n";
import { Provider } from 'react-redux';


import { ConfigProvider } from 'antd';

import {utils} from "utils";
import {store} from "Redux/store"
import "styles/App.scss";
import "assets/fonts/FontAwesomePro/css/all.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

ReactDOM.hydrate(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={utils.getLocale()} direction={utils.getDirection()}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
