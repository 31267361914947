import React from 'react'

export default function Loading() {
  return (
    <div style={{height: "100vh"}} className="d-flex justify-content-center align-items-center flex-column">
      <div className="loading-spinner">
        <div className="ldio">
          <div></div>
        </div>
      </div>
    </div>
  )
}
