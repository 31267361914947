import { Flex } from 'Components'
import i18n  from "i18next"
import React from 'react'
import { Fade, Slide } from 'react-reveal';
import ReactHtmlParser from 'react-html-parser';

import Article from "assets/images/article.png";
import Twitter from "assets/images/twitter.png";
import EmailImg from 'assets/images/email.png';
import linkedin from 'assets/images/linkedin.png';
import Constants from 'Constants';

export default function PersonInfo({
  person={},
}) {

  return (
    <Fade>
    <div style={{marginTop: "150px", marginRight: "20px", marginLeft: "20px"}} className="card bg-white shadow col-md-3 col-12 p-2">
      <Flex is_column align="center" justify="center">
        <img style={{bottom: "94%"}} src={`${Constants.BASE_URL}${person.image_url}` } alt="" className="w-25 rounded-circle position-absolute" />
        <h5 className="mt-3 pt-3 iranSansBold">{i18n.language === "fa" ? person.full_name_persian : person.full_name_english}</h5>
        <p className="mt-1 text-black-50">{person.role || ""}</p>
        <p style={{fontSize: ".8em"}} className="text-black-50 text-left LTR mb-0">{ReactHtmlParser(person.about_me)}</p>
        <Flex className="w-50" align="center" justify="center" is_column>
          <Flex justify="center" align="center">
            <a className="text-center"  href={`mailto:${person.email_field}`}><img src={EmailImg} className="w-50 m-2" /></a> 
            <a className="text-center" href={person.profile_link}><img src={linkedin} style={{width: "45%"}} className=" m-2" /></a> 
          </Flex>

        </Flex>
      </Flex>

    </div>
      
    </Fade>
  )
}
