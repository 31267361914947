import { BlankLayout, AppLayout } from "Layouts"
import { Main,
    Blog,
    BlogDetail,
    SuperAppDetail,
    AbousUs,
    TwitterAnalysis,
    TimePiece,
    PoliticalAnalysis,
    GeneralSurvey
 } from "Pages"

export default [
    {
        path: "/",
        component: Main,
        layout: AppLayout,
    },
    {
        path: "/survey",
        component: GeneralSurvey,
        layout: AppLayout,
    },
    {
        path: "/superapp/:id",
        component: SuperAppDetail,
        layout: AppLayout,
    },
    {
        path: "/blog",
        component: Blog,
        layout: AppLayout,
    },
    {
        path: "/blog/detail/:id",
        component: BlogDetail,
        layout: AppLayout,
    },
    {
        path: "/us",
        component: AbousUs,
        layout: AppLayout,
    },
    {
        path: "/twitter-analysis",
        component: TwitterAnalysis,
        layout: AppLayout,
    },
    {
        path: "/political-analysis",
        component: PoliticalAnalysis,
        layout: AppLayout,
    },
    {
        path: "/timepiece",
        component: TimePiece,
        layout: AppLayout,
    },
]