import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';
import createSagaMiddleware from 'redux-saga';
import saga from './saga';

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['language', 'login', 'session'],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancedComposer = composeWithDevTools({ trace: true });

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
if (process.env.REACT_APP_IS_REDUX_LOGGER_ACTIVE === 'active') {
}

let store = createStore(
  rootReducer,
  enhancedComposer(applyMiddleware(...middlewares))
);

// let persistor = persistStore(store);

store.rootTask = sagaMiddleware.run(saga, store);

export { store };
