import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Fade, Slide } from 'react-reveal';
import Constants from 'Constants';
import moment from "moment-jalaali";


export default function BlogPost({
    post = {},
    show_body = true
}) {

    return (
        <Fade>
            <div style={{ borderRadius: "25px" }} className="col-md-3 col-12 m-1 mt-5 card text-dark mb-3">
                <Link to={`/blog/detail/${post.id}`}>
                    <div style={{ backgroundImage: `url(${Constants.BASE_URL}${post.image_url})` }} class="top">
                    </div>

                    <div class="bottom">
                        <h6 className="iranSansBold m-3">{post.title}</h6>
                        {show_body === true && (
                            <>
                            {/* <p style={{ fontSize: ".9em" }} class="intro m-3 text-black-50">{post.body.slice(0, 150)}...</p> */}
                            </>
                        )}
                        <div class="info mt-3">
                            {/* <div class="author mx-3">
                                <div class="profile"></div>
                                <p style={{ fontSize: ".8em" }} className="text-black-50">{post.author}</p>
                            </div> */}
                            <p style={{ fontSize: ".8em" }} className="info">
                                <i style={{ marginTop: "2px" }} className="far fa-clock mx-1"></i>
                                {moment(post.created_time).format("jYYYY/jMM/jDD")}
                            </p>
                            <p style={{ fontSize: ".8em" }} class="info">

                            </p>
                        </div>
                    </div>
                </Link>
            </div>

        </Fade>

    )
}
