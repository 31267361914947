import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { isMobile, MobileView, isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Constants from "Constants";
import { Slide } from "react-reveal";
import { Col, Flex } from "Components";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import en_flag from "assets/images/en_flag.png";
import fa_flag from "assets/images/fa_flag.png";
import { setLang } from "Redux/modules/info";
export const defaultNavItems = [
  {
    path: "/",
    name: "Home",
    icon: "fa fa-home",
    onlyFA: false,
  },
  {
    path: "/survey",
    name: "GeneralSurvey",
    icon: "fa fa-poll-h",
    onlyFA: true,
  },
  {
    path: "/timepiece",
    name: "timepiece",
    icon: "fa fa-calendar",
    onlyFA: true,
  },
  {
    path: "/political-analysis",
    name: "PolicyAnalysis",
    icon: "fa fa-chart-bar",
    onlyFA: true,
  },
  {
    path: "/twitter-analysis",
    name: "TwitterAnalysis",
    icon: "fa fa-dove",
    onlyFA: true,
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "fa fa-clone",
    onlyFA: true,
  },
  {
    path: "/us",
    name: "AboutUs",
    icon: "fa fa-users",
    onlyFA: false,
  },
];

export default function Nav({
  nav_items = defaultNavItems,
  text_color = "#fff",
  bg_color = Constants.PRIMARY,
  has_search = true,
  curve = true,
  logo_text = "SuperAppWatch",
}) {
  const { t, i18n } = useTranslation();
  const { lang } = useSelector((state) => state.Info);
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = React.useState(false);

  return (
    <>
      <nav
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          // backgroundColor: bg_color,
          zIndex: 99999,
          borderBottomLeftRadius: curve === true ? "30px" : 0,
          borderBottomRightRadius: curve === true ? "30px" : 0,
          direction: "rtl",
        }}
        className="position-sticky shadow bg-primary"
      >
        {isMobile === true ? (
          <button
            onClick={() => setShowSideBar(!showSideBar)}
            className="btn text-white"
          >
            <i
              className={`fa ${showSideBar === true ? "fa-times" : "fa-bars"}`}
            ></i>
          </button>
        ) : (
          <></>
        )}
        <div style={{
          direction: lang === "EN" ? "ltr" : "revert"
        }} id="menu">
          {isMobile === false &&
            nav_items.map((nav_item, i) => {
              if (lang === "EN") {
                if (nav_item?.onlyFA == false) {
                  return (
                    <NavLink
                      // onClick={() => {
                      //     document.getElementById(nav_item.name).scrollIntoView()
                      // }}
                      style={{ color: text_color, }}
                      key={`nav-${i}`}
                      to={nav_item.path}
                      activeClassName="nav-active"
                      className="nav-item  mx-2"
                    >
                      <i className={`${nav_item.icon} m-2`}></i>
                      {t(nav_item.name)}
                    </NavLink>
                  );
                }
              } else {
                return (
                  <NavLink
                    // onClick={() => {
                    //     document.getElementById(nav_item.name).scrollIntoView()
                    // }}
                    style={{ color: text_color }}
                    key={`nav-${i}`}
                    to={nav_item.path}
                    activeClassName="nav-active"
                    className="nav-item  mx-2"
                  >
                    <i className={`${nav_item.icon} m-2`}></i>
                    {t(nav_item.name)}
                  </NavLink>
                );
              }
            })}
          {lang === "EN" && (
            <Button
              style={{ width: "5%" }}
              className="btn"
              onClick={() => {
                dispatch(setLang("FA"));
                i18n.changeLanguage("fa");
              }}
            >
              <img style={{ width: "90%" }} src={fa_flag} />
            </Button>
          )}

          {lang === "FA" && (
            <Button
              style={{ width: "5%" }}
              className="btn"
              onClick={() => {
                dispatch(setLang("EN"));
                i18n.changeLanguage("en");
              }}
            >
              <img style={{ width: "90%" }} src={en_flag} />
            </Button>
          )}
        </div>

        <div className="row">
          {has_search === true && (
            <>
              {/* <div className={`col-6 input-icons search position-relative`}>
                        <i className="fa fa-search icon text-black-50"></i>
                        <input style={{backgroundColor: "#fff"}} placeholder={t("Search...")} className="form-control rounded-pill border-0" />                    
                    </div> */}
            </>
          )}
          <div className="col-3">
            <a
              style={{ textDecoration: "none" }}
              className="text-white"
              href="/"
            >
              <h6
                style={{ fontSize: isMobile ? ".6em" : "inherit" }}
                className="font-logo mt-2"
              >
                {logo_text}
              </h6>
            </a>
          </div>
        </div>
      </nav>
      {showSideBar === true ? (
        <Slide>
          <div className="">
            <h4 className="iranSansBold m-3 text-center">{t("QuickAccess")}</h4>
            <Flex justify="center" align="center">
              <div style={{ width: "10%" }} className="line  mt-1 mb-4"></div>
            </Flex>
            <div className="row g-0 d-flex justify-content-center align-items-center">
              {defaultNavItems.map((item, index) => {
                return (
                  <Col md={3} className="text-center m-2">
                    <NavLink
                      // onClick={() => {
                      //     document.getElementById(nav_item.name).scrollIntoView()
                      // }}
                      key={`footer-nav-${index}`}
                      to={item.path}
                      className="nav-item w-100 text-dark mx-2 d-flex justify-content-center align-items-center w-100"
                    >
                      <i className={`${item.icon} m-2`}></i>
                      {i18n.t(item.name)}
                    </NavLink>
                  </Col>
                );
              })}
            </div>
          </div>
        </Slide>
      ) : (
        <></>
      )}
    </>
  );
}
