import { all, takeEvery } from 'redux-saga/effects';
// import { REHYDRATE } from 'redux-persist';


import { actionTypes as getBlogPosts, watchGetBlogPost}  from './modules/Blog/getBlogPosts';
import { actionTypes as getBlogPostDetail, watchGetBlogPostDetail}  from './modules/Blog/getBlogPostDetail';
import { actionTypes as getAboutUs, watchGetAboutUs}  from './modules/AboutUs';
import { actionTypes as getMapData, watchGetMapData}  from './modules/Map';
import { actionTypes as getCountryAppsModal, watchGetCountryAppsModal}  from './modules/Map/CountryAppsModal';
import { actionTypes as getCounters, watchGetCounters}  from './modules/Counters';
import { actionTypes as SuperAppDetail, watchLoadSuperAppDetail}  from './modules/SuperApp/SuperAppDetail';
import { actionTypes as SuperApps, watchLoadSuperApps}  from './modules/SuperApp/SuperApp';
import { actionTypes as TwitterData, watchLoadTwitterData}  from './modules/TwitterData';
import { actionTypes as PoliticalAnalysis, watchLoadPoliticalAnalysis}  from './modules/PoliticalAnalysis';
import { actionTypes as sendSurveyData, watchSendSurveyData}  from './modules/Survey';


export default function* root(store) {
  yield all([
    takeEvery(getBlogPosts.load, watchGetBlogPost),
    takeEvery(getBlogPostDetail.load, watchGetBlogPostDetail),
    takeEvery(getAboutUs.load, watchGetAboutUs),
    takeEvery(getMapData.load, watchGetMapData),
    takeEvery(getCountryAppsModal.load, watchGetCountryAppsModal),
    takeEvery(getCounters.load, watchGetCounters),
    takeEvery(SuperAppDetail.load, watchLoadSuperAppDetail),
    takeEvery(SuperApps.load, watchLoadSuperApps),
    takeEvery(TwitterData.load, watchLoadTwitterData),
    takeEvery(PoliticalAnalysis.load, watchLoadPoliticalAnalysis),
    takeEvery(sendSurveyData.load, watchSendSurveyData),
  ]);
}