import { Flex } from 'Components'
import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {isMobile} from 'react-device-detect';


import Col from './Col'
import NokhbeLogo from "assets/images/bonyad-nokhbe.png";
import AndishehLogo from "assets/images/andisheh.png";
import AzmayeshgahLogo from "assets/images/azmayeshgah.png";
import SharifLogo from "assets/images/sharif.png";

import { defaultNavItems } from "./Nav";
import { useSelector } from 'react-redux';

export default function Footer() {
    const { t, i18n } = useTranslation();
    const {lang} = useSelector(state => state.Info)
    return (
        <div className="pb-1 bg-white" id="">
            <Flex justify="center" align="center">

                <div className="row d-flex justify-content-center">
                    <Flex justify="center" align="center">
                        <a className="w-25 d-flex justify-content-center" href="https://www.bmn.ir/">
                            <img className="mt-2 mx-3" style={{ width: isMobile ? "90%" : "40%" }} src={NokhbeLogo} />
                        </a>
                        <a herf={"http://ristip.sharif.ir/#"} className="w-25 d-flex justify-content-center">
                            <img className="mt-2 mx-3" style={{ width: isMobile ? "62%" : "28%" }} src={AndishehLogo} />
                        </a>
                        <a href="http://gptt.ir/" className="w-25 d-flex justify-content-center">
                            <img className="mt-2 mx-3" style={{ width: isMobile ? "70%" : "35%" }} src={SharifLogo} />
                        </a>
                        <a href={"https://d4g-lab.com/"} className="w-25 d-flex justify-content-center">
                            <img className="mt-2 mx-3" style={{ width: isMobile ? "85%" : "40%" }} src={AzmayeshgahLogo} />
                        </a>
                    </Flex>

                </div>
            </Flex>

            {/* <h4 className="iranSansBold m-3 text-center">در خبرنامه ما عضو شوید</h4>
            <Flex justify="center" align="center">
                <div style={{ width: "10%" }} className="line  mt-1 mb-4"></div>
            </Flex>
            <Flex justify="center" align="center">
                <input placeholder="ایمیل شما" className="form-control w-50 rounded-pill" />
                <button className="btn btn-dark rounded-pill mx-3">عضویت</button>
            </Flex> */}
            <div className="row g-0 mt-1">
                <Col md={6} xs={12} className="border-start">
                    <h4 className="iranSansBold m-3 text-center">{t("contactUs")}</h4>
                    <Flex justify="center" align="center">
                        <div style={{ width: "10%" }} className="line  mt-1 mb-4"></div>
                    </Flex>
                    <Flex justify="center" align={"center"} is_column>
                        <p className="text-center LTR">
                            <i className="fa fa-phone-alt m-1"> </i>
                            <span className="iranSansBold">02166068518</span>
                        </p>
                        {/* <p className="text-center LTR">
                            <i className="fa fa-at m-1"></i>
                            <span className="iranSansBold">info@superappwatch.com</span>
                        </p> */}
                        <p className="text-center w-75">
                            <i className="fa fa-map-marker m-1"></i>
                            <span className="iranSansBold">{t("address")}</span>
                        </p>
                    </Flex>
                </Col>
                <Col md={5} xs={12}>
                    <h4 className="iranSansBold m-3 text-center">{t("QuickAccess")}</h4>
                    <Flex justify="center" align="center">
                        <div style={{ width: "10%" }} className="line  mt-1 mb-4"></div>
                    </Flex>
                    <div className="row g-0 d-flex justify-content-center align-items-center">
                        {
                            defaultNavItems.map((item, index) => {
                                if (lang === "EN" && item.onlyFA === true) {
                                    return
                                } else {
                                    return (
                                        <Col md={3} className="text-center m-2">
                                            <Link
                                                // onClick={() => {
                                                //     document.getElementById(nav_item.name).scrollIntoView()
                                                // }}
                                                key={`footer-nav-${index}`}
                                                to={item.path}
                                                className="nav-item w-100 text-dark mx-2 d-flex justify-content-center align-items-center w-100">
                                                <i className={`${item.icon} m-2`}></i>
                                                {i18n.t(item.name)}
                                            </Link>
                                        </Col>
                                    )
                                }
                            })
                        }
                    </div>

                </Col>
            </div>
            <p className="text-center text-black-50 iranSansBold mt-5">{t("copyRight")}</p>

        </div>
    )
}
