import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';
  

const route = '/api/apps/country/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'CountryAppsModal'
);

export function* watchGetCountryAppsModal({ payload }) {
  
  try {
    const result  = yield call(utils.API, route, "POST", payload);
   
    yield put(actions.loadSuccess(result))
  } catch (e) {
    console.error(e)
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;