import React from 'react';
import { Col, Row, Flex, Question, Container, Loading } from 'Components';
import { Fade, Slide } from 'react-reveal';

import SurveyConcept from "assets/images/survey-concept.png";

import { useDispatch, useSelector } from 'react-redux';
import { actions as  sendSurveyData } from 'Redux/modules/Survey';
import { withRouter } from 'react-router-dom';
import { utils } from 'utils';



const QuestionsData = [
    {   
        id: 1,
        type: "slider",
        title: "بازه سنی خود را مشخص کنید",
        has_text: false,
        min: 5,
        max: 90
    },
    {   
        id: 2,
        type: "radio",
        title: "جنسیت خود را مشخص کنید",
        has_text: false,
        answers: [
            {
                label: "مرد",
                value: "GENDER_MAN",
            },
            {
                label: "زن",
                value: "GENDER_WOMAN",
            },
        ],
    },
    { 
        id: 3,
        type: "radio",
        title: "تحصیلات خود را مشخص کنید",
        has_text: false,
        answers: [
            {
                label: "دیپلم و زیر دیپلم",
                value: "دیپلم و زیر دیپلم ",
            },
            {
                label: "کارشناسی و دانشجوی کارشناسی",
                value: "کارشناسی و دانشجوی کارشناسی",
            },
            {
                label: "کارشناسی ارشد و دانشجوی ارشد",
                value: "کارشناسی ارشد و دانشجوی ارشد",
            },
            {
                label: "دکترا و بالاتر",
                value: "دکترا و بالاتر",
            },
        ],
    },
    { 
        id: 4,
        type: "radio",
        title: "درآمد خود را مشخص کنید",
        has_text: false,
        answers: [
            {
                label: "کمتر از سه میلیون تومان",
                value: "کمتر از سه میلیون تومان ",
            },
            {
                label: "سه تا شش میلیون تومان",
                value: "سه تا شش میلیون تومان",
            },
            {
                label: "شش تا ده میلیون تومان",
                value: "شش تا ده میلیون تومان",
            },
            {
                label: "ده میلیون تومان و بالاتر",
                value: "ده میلیون تومان و بالاتر",
            },
        ],
    },
    {   id: 5,
        type: "radio",
        title: "آیا دارای نوعی کم‌توانی هستید؟ در صورت تمایل توضیح دهید",
        has_text: true,
        answers: [
            {
                label: "بله",
                value: "YES",
            },
            {
                label: "خیر",
                value: "NO",
            },
        ],
    },
    {   id: 6,
        type: "radio",
        title: "آیا تا بحال واژه سوپراپ یا سوپراپلیکیشن را شنیده‌اید؟ درباره کدام اپ‌ها؟",
        answers: [
            {
                label: "بله",
                value: "YES",
            },
            {
                label: "خیر",
                value: "NO",
            },
        ],
        has_text: true,
    },
    {   
        id: 7,
        type: "checkbox",
        title: "فکر می‌کنید کدام اپلیکیشن‌های زیر سوپراپ هستند؟",
        has_text: false,
        addable: true,
        answers: [
            {
                label: "اسنپ",
                value: "اسنپ",
            },
            {
                label: "روبیکا",
                value: "روبیکا",
            },
            {
                label: "آپ (آسان پرداخت)",
                value: "آپ (آسان پرداخت)",
            },
            {
                label: "تلگرام",
                value: "تلگرام",
            },
        ],
    },
    {   
        id: 8,
        type: "checkbox",
        title: "از کدام سوپراپ‌های زیر استفاده می‌کنید؟",
        has_text: false,
        addable: true,
        answers: [
            {
                label: "اسنپ",
                value: "اسنپ",
            },
            {
                label: "روبیکا",
                value: "روبیکا",
            },
            {
                label: "آپ (آسان پرداخت)",
                value: "آپ (آسان پرداخت)",
            },
            {
                label: "ایرانسل من",
                value: "ایرانسل من",
            },
            {
                label: "دیجی‌پی‌",
                value: "دیجی‌پی‌",
            },
        ],
    },
    {   
        id: 9,
        type: "checkbox",
        title: "از کدام خدمات سوپراپ‌ها بیشترین استفاده را دارید؟",
        has_text: false,
        addable: true,
        answers: [
            {
                label: "شبکه اجتماعی (مثل روبینو)",
                value: "شبکه اجتماعی (مثل روبینو)",
            },
            {
                label: "خدمات پرداخت دیجیتال ",
                value: "خدمات پرداخت دیجیتال ",
            },
            {
                label: "سفارش غذا",
                value: "سفارش غذا",
            },
            {
                label: "سفارش خرید از سوپرمارکت",
                value: "سفارش خرید از سوپرمارکت",
            },
            {
                label: "خدمات سفارش تاکسی",
                value: "خدمات سفارش تاکسی",
            },
            {
                label: "خدمات درمانی-بهداشتی",
                value: "خدمات درمانی-بهداشتی",
            },
            {
                label: "تماشای فیلم و سریال",
                value: "تماشای فیلم و سریال",
            },
        ],
    },
    {   
        id: 10,
        type: "checkbox",
        title: "کدام یک از موارد زیر را بخشی از حریم خصوصی خود در فضای مجازی می‌دانید؟ (تمایل ندارید این اطلاعات با کسان دیگری به اشتراک گذاشته شود.) ",
        has_text: false,
        addable: false,
        answers: [
            {
                label: "تصاویر، ویدیو و صوتی که خودتان در فضای مجازی منتشر می‌کنید",
                value: "تصاویر، ویدیو و صوتی که خودتان در فضای مجازی منتشر می‌کنید",
            },
            {
                label: "تصاویر، ویدیو و صوتی که در حافظه گوشی شما ذخیره شده‌اند ",
                value: "تصاویر، ویدیو و صوتی که در حافظه گوشی شما ذخیره شده‌اند ",
            },
            {
                label: "آدرس و اطلاعات تماس شما",
                value: "آدرس و اطلاعات تماس شما",
            },
            {
                label: "آدرس مکان‌هایی که به آنها رفت و آمد دارید",
                value: "آدرس مکان‌هایی که به آنها رفت و آمد دارید",
            },
            {
                label: "شماره ملی، شماره بیمه و سایر اطلاعات هویتی شما",
                value: "شماره ملی، شماره بیمه و سایر اطلاعات هویتی شما",
            },
            {
                label: "شماره کارت ماشین و  شماره کارت بانکی",
                value: "شماره کارت ماشین و  شماره کارت بانکی",
            },
            {
                label: "اطلاعات رستوران‌ها و فروشگاه‌هایی که به صورت اینترنتی از آنها خرید می‌کنید",
                value: "اطلاعات رستوران‌ها و فروشگاه‌هایی که به صورت اینترنتی از آنها خرید می‌کنید",
            },
            {
                label: "ترجیحات و سلیقه شما در خرید محصولات خوراکی",
                value: "ترجیحات و سلیقه شما در خرید محصولات خوراکی",
            },
            {
                label: "سوابق درمانی و بهداشتی",
                value: "سوابق درمانی و بهداشتی",
            },
            {
                label: "ترجیحات و سلیقه شما در مورد بازی‌ها، فیلم و سریال و ویدیوکلیپ‌ها",
                value: "ترجیحات و سلیقه شما در مورد بازی‌ها، فیلم و سریال و ویدیوکلیپ‌ها",
            },
        ],
    },
]

function GeneralSurvey({history}) {
    const [surveyData, setSurveyData] = React.useState([]);
    const [is_loading, setLoading] = React.useState(true);
    const dispatch = useDispatch();
    const SurveyResponseData = useSelector(state => state.SurveyResponse)

    const onRespond = (question, answer, q_i, has_text=false, text="") =>  {
        const dataIndex = surveyData.findIndex(el => el.question.id === question.id)
        
        // Handle Check Boxes
        if (question.type === "checkbox") {
            if (dataIndex === -1) {
                surveyData.push({
                    question,
                    answers: [answer.value]
                })    
            }  else {
                const answ_index = surveyData[dataIndex]['answers'].findIndex(el => el === answer.value)
                if (answ_index === -1) {
                    surveyData[dataIndex]['answers'].push(answer.value)
                } else {
                    surveyData[dataIndex]['answers'].splice(answ_index, 1)
                    if (surveyData[dataIndex]['answers'].length === 0) {
                        surveyData.splice(dataIndex, 1);
                    }
                }
            }
        } else  {
            if (dataIndex === -1) {
                surveyData.push({
                    question,
                    answers: [answer]
            })
        } else {
            surveyData.splice(dataIndex, 1);
            surveyData.push({
                question,
                answers: [answer]
            })
        }            
        }

        // Handle Text Descriptions

        if (has_text === true) {
            surveyData[dataIndex]['description'] = text;
        } else {
            // surveyData[dataIndex]['description'] = null;
            
        }

        setSurveyData(surveyData);
    }   


    const submitData = (e) => {
        e.preventDefault();
        if (surveyData.length !== 0) {
            const data = {
                body: surveyData,
            }
            dispatch(sendSurveyData.load(data));        
            history.push("/")
        }
    }
    



    if (SurveyResponseData.loading === true) {
        return <Loading />
    } 
    
    
    return (
            <>
            {/* Header */}
                <Row>
                    <Col className=" d-flex flex-column justify-content-center align-items-center text-dark" md={6} xs={12}>
                        <Slide right>
                            <h1 className="iranSansBold mt-4">پیمایش عمومی رصدگر</h1>
                        </Slide>
                        <Fade delay={1000}>
                            <p>با جواب دادن به سوالات زیر به ما در جمع آوری اطلاعات بیشتر کمک کنید</p>
                        </Fade>
                        <Fade delay={1200}>
                            <a href="#survey" className="btn btn-dark w-50 rounded-pill">
                                مشاهده پرسشنامه
                            </a>
                        </Fade>
                    </Col>
                    <Col className="mt-5" md={6}>
                        <Slide left big>
                            <Flex justify="center" align="center">
                                <img width={"85%"} src={SurveyConcept} />
                            </Flex>
                        </Slide>
                    </Col>
                </Row>
    
                {/* Questions Here */}
                <div id="survey">
                    <Container>
                            <Row>
                        <Flex is_column>
                                {
                                    QuestionsData.map( (q,i) => {
                                        return (
                                            <Question
                                                key={i}
                                                index={i}
                                                question={q}
                                                onRespond={onRespond}
    
                                            />
                                        )
                                    } )
                                }
                        </Flex>
                            </Row>
                        
                        <Flex justify="center" align="center">
                            <button onClick={submitData} className="btn btn-primary w-50 mt-5 mb-3">ثبت اطلاعات</button>
                        </Flex>
                        <Flex justify="center" align="center">
                            <button onClick={() => {
                                navigator.clipboard.writeText("https://superappwatch.com/survey").then(() => {
                                    utils.showAlert(
                                        "با موفقیت کپی شد",
                                        "لینک پیمایش با موفقیت کپی شد"
                                        )
                                })
                            }} className="btn btn-danger w-50 mb-5">اشتراک گذاری</button>
                        </Flex>
                    </Container>
            
                </div>
            </>
        )

  
}



export default withRouter(GeneralSurvey)