import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';
  

const route = 'api/apps/all-brief/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'SuperApps'
);

export function* watchLoadSuperApps({ payload }) {
  
  try {
    let result  = yield call(utils.API, route, "GET");
    
    if (payload.top === true) {
        result = result.sort((a,b) =>  {
            if (parseInt(b.monthly_active_users) > parseInt(a.monthly_active_users)) {
                return 1
            } else {
                return -1
            }

        } )
        yield put(actions.loadSuccess(result.slice(0,5)));

    } else {
        yield put(actions.loadSuccess(result));
    }

    
  } catch (e) {
    console.error(e)
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;