import React from 'react'

export default function Col({md=12, xs="12", children, className }) {
    return (
        <div 
            className={`col-md-${md} col-${xs} ` + className}
        >
            {children}
        </div>
    )
}
