import React from 'react'

export default function Table({
  headers = [],
  rows = [],
  bg_class = "bg-white",
  shadow = true,
  rounded = true,
  header_color = "#232526",
  headerTextColor = "#fff",
  align = "center", // right | left | center
  minWidth = "1200px"

}) {
  return (
    <>
      <div style={{ borderRadius: rounded === true ? "20px" : "0px" }} className={`${bg_class} ${shadow === true ? "shadow" : ""}  pb-2 mb-5 `}>
        <div className="table overflow-auto">
          <div
            style={{
              backgroundColor: header_color,
              color: headerTextColor,
              borderTopRightRadius: rounded === true ? "20px" : 0,
              borderTopLeftRadius: rounded === true ? "20px" : 0,
              minWidth: minWidth
            }}
            className="table-headers d-flex p-2">
            {
              headers.map((header, i) => {
                return (
                  <div key={`header-${i}`} style={header.style} className={`text-${align} iranSansBold`}>
                    {header.title}
                  </div>
                )
              })
            }
          </div>

          <div style={{ minWidth: minWidth }} className="table-body d-flex flex-column">
            {
              rows.length === 0 ||  rows.length === undefined || rows === undefined ? (
                <div className="d-flex flex-wrap flex-row my-2 py-1 contents-row text-center justify-content-center"> اطلاعاتی برای نمایش وجود ندارد</div>
              ) : (
                <>
                  {
                    typeof rows !== undefined && rows.map((row, i_r) => {
                      return (
                        <div
                          key={`row-${i_r}`}
                          style={{ fontSize: "14px" }}
                          className={`d-flex flex-wrap flex-row my-2 py-1 ${i_r % 2 === 0 ? "contents-row" : "odd-contents-row"}`} >
                          {
                            headers.map((header, i_h) => {
                              return (
                                <div
                                  key={`h-${i_h}`}
                                  style={header.style}
                                  className={`text-center ${header.className || ''}  `}
                                >
                                  {header.getData(row, i_r)}
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </>
              )
            }

          </div>
        </div>
      </div>
    </>
  )
}
