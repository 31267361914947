import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';
  

const route = '/api/apps/countries-apps/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'mapData'
);

export function* watchGetMapData({ payload }) {
  
  try {
    const result  = yield call(utils.API, route, "GET");
    const data = {};
    const hc_data = [];
    data['result'] = result;
    
    result.map((country, i) => {
      const inner_arr = [];
      inner_arr.push(country.key, parseInt(country.numbers) )
      hc_data.push(inner_arr)
    })

    data['hc_data'] = hc_data;

    yield put(actions.loadSuccess(data))
  } catch (e) {
    console.error(e)
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;