const initialState = {
    lang: "EN",
  }

const SET_LANG = "SET_LANG"
  
export const setLang = (lang) => {
    return {
        type: SET_LANG,
        payload: lang
    }
}

export default function infoReducer(state = initialState, action) {
// The reducer normally looks at the action type field to decide what happens
switch (action.type) {
    case SET_LANG:
        return {
            ...state,
            lang: action.payload
        }
    
    // Do something here based on the different types of actions
    default:
    // If this reducer doesn't recognize the action type, or doesn't
    // care about this specific action, return the existing state unchanged
    return state
}
}