import React from 'react'
import { Col, Row, Flex, PersonInfo, Loading } from "Components";
import { useTranslation } from 'react-i18next';
import { Fade, Slide } from 'react-reveal';
import AbousUsImg from 'assets/images/about-us.png';
import ContactUsImg from 'assets/images/contact-us.png';
import { actions as  getAboutUs } from 'Redux/modules/AboutUs';
import { useDispatch, useSelector } from 'react-redux';



export default function AbousUs() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const teamMembersData = useSelector(state => state.AboutUs)

  React.useEffect(() => {
    dispatch(getAboutUs.load())
  }, [])


  console.log(teamMembersData)
  
  return (
    <div id="about-us" className="bg-white ">
      {/* <Row>
        <Col className=" d-flex flex-column justify-content-center align-items-center text-dark" md={6} xs={12}>
          <Slide top big>
            <h1 className="iranSansBold ">درباره ما...</h1>
          </Slide>
          <Fade delay={1000}>
            <h4>با ما بیشتر آشنا بشید :)</h4>
          </Fade>
        </Col>
        <Col className="mt-5" md={6}>
          <Slide left big>
            <Flex justify="center" align="center">
              <img width={"100%"} src={AbousUsImg} />
            </Flex>
          </Slide>
        </Col>
      </Row> */}
      <h1 className="iranSansBold text-center pt-5">{t("ourTeam")}</h1>
      <Flex justify="center" align="center">
                <div style={{width: "10%"}} className="line  mt-1 mb-4"></div>
            </Flex>
      <Row className="row  d-flex justify-content-around">
        {
          teamMembersData.loading === true ? <Loading /> : (
            <>
              {
                teamMembersData && teamMembersData.data && teamMembersData.data.length  && teamMembersData.data.map((member, i) => {
                  return <PersonInfo key={`person-${i}`} person={member} />
                })
              }
            </>
          )
        }
      </Row>
      
      {/* <div className="bg-primary text-white p-5 mt-5">
        <Row>
        <Col className="mt-5" md={6}>
          <Fade >
            <Flex justify="center" align="center">
              <img width={"100%"} src={ContactUsImg} />
            </Flex>
          </Fade>
        </Col>
        <Col className=" d-flex flex-column justify-content-center align-items-center text-white" md={6} xs={12}>
          <Slide top big>
            <h1 className="text-center iranSansBold">با ما در تماس باشید...</h1>
          </Slide>
          <Fade delay={1000}>
            <h4 className="mt-5 iranSans"><i className="fa fa-envelope"></i> <a className="text-white" href="tel:02122334455">info@superappwatch.com</a></h4>
          </Fade>
          <Fade delay={1200}>
            <h4 className="mt-3 iranSans"><i className="fa fa-phone"></i> <a className="text-white" href="tel:02122334455">02166068518</a></h4>
          </Fade>
          <Fade delay={1400}>
            <h5 className="mt-3 iranSans"><i className="fa fa-map-marker"></i> تهران، خیابان آزادی، خیابان حبیب اللهی، خیابان قاسمی، کوچه تیموری، بن بست گوهر، پلاک 2</h5>
          </Fade>
            
        </Col>
      </Row>
      </div> */}
    </div>
  )
}
