import React from 'react'
import { Flex } from 'Components';
export default function Slider({
  images = [],
  height="600px"
}) {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const onNext = () => {
    if (currentSlide+1 > images.length) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  }

  const onPrev = () => {
    if (currentSlide === 0) {
      setCurrentSlide(images.length)
      return
    }
    
    if (currentSlide - 1 < 0) {
      setCurrentSlide(images.length )
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  }
  
  return (
    <>
    <Flex justify="center" align="center">
      <div style={{ width: "90%"}} className="carousel slide " data-ride="carousel">

        <div style={{}} className="carousel-inner pt-4">
          {
            images.map((image, i) => {
              return (
                <div key={i} className={`carousel-item ${i === currentSlide ? "active" : ""}`}>
                  <img style={{height: "auto"}} class="d-block w-100" src={image} alt="" />
                </div>
              )
            })
          }

          {/* <div class="carousel-item">
      <img class="d-block w-100" src={banner1} alt="Second slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src={banner2} alt="Third slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src={banner3} alt="Third slide"/>
    </div> */}
        </div>
        <button onClick={onPrev} className="carousel-control-prev"  role="button" data-slide="prev">
          <i className="fa fa-chevron-left fa-3x text-dark"></i>
          {/* <span className="sr-only">Previous</span> */}
        </button>
        <button onClick={onNext} className="carousel-control-next"  role="button" data-slide="next">
            <i className="fa fa-chevron-right fa-3x text-dark"></i>
      
        </button>
      </div>

  </Flex>

    </>
  )
}
