import React, { useRef, useState, useEffect } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Fade, Slide } from "react-reveal";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import World from "@highcharts/map-collection/custom/world.geo.json";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";

import {
  Row,
  Col,
  Wave,
  Flex,
  Nav,
  Chart,
  Title,
  BlogPost,
  Footer,
  Table,
  Loading,
} from "Components";

import { actions as loadBlogPosts } from "Redux/modules/Blog/getBlogPosts";
import { actions as loadMapData } from "Redux/modules/Map";
import { actions as loadCountryAppsModal } from "Redux/modules/Map/CountryAppsModal";
import { actions as loadCounters } from "Redux/modules/Counters";
import { actions as loadSuperApps } from "Redux/modules/SuperApp/SuperApp";

import dataAnalys from "assets/images/business-analysis.png";
import teamIcon from "assets/images/team.png";
import appIcon from "assets/images/appIcon.png";
// import dataAnalys from "assets/images/data-analysis.png"

// import dataAnalys from "assets/images/data-analysis-concept-with-character.png";
// import waveImg from "assets/images/wave.png";
// import waveImg2 from "assets/images/wave-2.png";
// import waveImg2Down from "assets/images/wave-2-down.png";
// import worldWide from "assets/images/worldwide.png";
// import Article from "assets/images/article.png";
// import Twitter from "assets/images/twitter.png";
// import BlogIcon from "assets/images/blog.png";
// import countryIcon from "assets/images/country.png";
// import categoriesIcon from "assets/images/categories.png";

// import mainPageVideo from "assets/main_page.mp4";
import { CountrySuperAppsModal } from "Modals";
import { utils } from "utils";
import Constants from "Constants";
import { isMobile } from "react-device-detect";

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: "75%",
    width: "75%",
    height: "95%",
    transform: "translate(-50%, -50%)",
    direction: "rtl", //utils.getDirection(),
  },
};

const getTableHeaders = (t) => {
  return [
    // {
    //     title: "",
    //     getData: (item, index) => {
    //         return <img className="w-100" src={Constants.BASE_URL + item.logo} alt="" />
    //     },
    //     style: {
    //         width: "%"
    //     }
    // },
    {
      title: "#",
      getData: (item, index) => {
        // return index + 1;
        return (
          <img className="w-75" src={Constants.BASE_URL + item.logo} alt="" />
        );
      },
      style: {
        width: "10%",
      },
    },
    {
      title: t("Name"),
      getData: (item, index) => {
        return <p className="iranSansBold">{item.name}</p>;
      },
      style: {
        width: "20%",
      },
    },
    // {
    //     title: "لوگو",
    //     getData: (item, index) => {
    //         return item.name;
    //     },
    //     style: {
    //         width: "15%"
    //     }
    // },
    {
      title: t("Activity"),
      getData: (item, index) => {
        return <p className="iranSansBold">{item.core_asset}</p>;
      },
      style: {
        width: "19%",
      },
    },
    // {
    //   title: t("Starting Year"),
    //   getData: (item, index) => {
    //     return <p className="iranSansBold">{item.start_year || "-"}</p>;
    //   },
    //   style: {
    //     width: "15%",
    //   },
    // },
    {
      title: t("Country"),
      getData: (item, index) => {
        return (
          <p className="iranSansBold">{item.country_of_origin.name || "-"}</p>
        );
      },
      style: {
        width: "15%",
      },
    },
    {
      title: t("Monthly Users"),
      getData: (item, index) => {
        return (
          <p className="iranSansBold">
            {utils.numberWithCommas(item.monthly_active_users) || "-"}
          </p>
        );
      },
      style: {
        width: "15%",
      },
    },
    {
      title: t("Operation"),
      getData: (item, index) => {
        return (
          <Link to={`/superapp/${item.id}`}>
            <button className="btn btn-primary rounded-pill w-50">
              {t("Details")}
            </button>
          </Link>
        );
      },
      style: {
        width: "20%",
      },
    },
  ];
};

export default function Main() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const BlogPosts = useSelector((state) => state.getBlogPosts);
  const MapData = useSelector((state) => state.MapData);
  const { lang } = useSelector((state) => state.Info);
  const Counters = useSelector((state) => state.Counters);
  const CountryAppsData = useSelector((state) => state.CountryAppsModal);
  const SuperAppsData = useSelector((state) => state.SuperApps);
  const Home = useRef(null);
  const Map = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [openCountry, setOpenCountry] = useState("");
  const [chart, setChart] = useState(null);
  const [TopSuperAppsHeaders, setHeaders] = useState(getTableHeaders(t));

  useEffect(() => {
    setHeaders(getTableHeaders(t));
  }, [t, lang]);

  useEffect(() => {
    dispatch(loadMapData.load());
    dispatch(loadCounters.load());
    dispatch(loadBlogPosts.load());
    dispatch(loadSuperApps.load({ top: true }));
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openCountrySuperApps = (event) => {
    console.log(event);
    const payload = {
      // name: event.properties.properties,
      key: event.options["hc-key"],
    };

    dispatch(loadCountryAppsModal.load(payload));
    setOpenCountry(event.name);
    openModal();
  };

  const SuperAppsTableData = SuperAppsData.data || [];

  return (
    <>
      {/* <img style={{ height: "250px", width: "100%" }} src={waveImg} /> */}
      <div className="mt-5" id="Map">
        <Title text={t("WorldWideSuperAppsMap")} />
        {/* <h5 className="text-center">برای دریافت اطلاعات هر کشور بر روی آن کلیک کنید</h5> */}
        <div className="my-3 w-100">
          <div className={`col-12  col-md-12`}>
            {MapData.loading === true ? (
              <Loading />
            ) : (
              <Chart
                callback={(chart) => {
                  setChart(chart);
                }}
                onCountryClick={openCountrySuperApps}
                type="map"
                map={World}
                series={[
                  {
                    name: "",
                    dataLabels: {
                      enabled: false,
                      format: "{point.name}",
                    },
                    data: MapData && MapData.data && MapData.data.hc_data,
                  },
                ]}
              />
            )}
          </div>
        </div>
        <Flex justify="center" align="center">
          <Row className="d-flex justify-content-center">
            <div id="Home" className="not-bg-primary">
              <Row className="d-flex justify-content-center align-items-center">
            
                {Counters.loading === true ? (
                  <Loading />
                ) : (
                  <Col
                  classNames="mt-5 d-flex flex-column justify-content-center align-items-center text-white d-flex"
                  md={6}
                  xs={12}
                >
                  <Flex justify="center" align="center">
                    <div className="row g-0 d-flex justify-content-around m-3">
                      <Fade delay={900}>
                        <div className="bg-white shadow rounded col-md-4 col-12 m-3 p-2">
                          <Flex justify="center" align="center" is_column>
                            <img src={teamIcon} style={{ width: isMobile ? "15%" : "30%" }} />
                            <CountUp
                              separator={","}
                              className="iranSansBold text-dark  my-3"
                              start={0}
                              end={Counters.data && Counters.data.users}
                              style={{ fontSize: "2em" }}
                              duration={3.5}
                            />
                            <h4 className="iranSansBold">{t("totalUsers")}</h4>
                          </Flex>
                        </div>
                      </Fade>
                      <Fade delay={1100}>
                        <div className="bg-white shadow rounded col-md-4 col-12 m-3 p-2">
                          <Flex justify="center" align="center" is_column>
                            <img src={appIcon} style={{ width: isMobile ? "15%" : "30%" }} />

                            <CountUp
                              separator={","}
                              className="iranSansBold text-dark  my-3"
                              start={0}
                              end={Counters.data && Counters.data.apps}
                              style={{ fontSize: "2em" }}
                              duration={2}
                            />
                            <h4 className="iranSansBold">{t("superApp")}</h4>
                          </Flex>
                        </div>
                      </Fade>

                      {/* <Fade delay={500}>
                        <div className="bg-white shadow rounded col-md-4 col-12 m-3 p-2">
                          <Flex justify="center" align="center" is_column>
                            <img
                              src={categoriesIcon}
                              style={{ width: isMobile ? "15%" : "30%" }}
                            />
                            <CountUp
                              separator={","}
                              className="iranSansBold text-dark  my-3"
                              start={0}
                              end={32}
                              style={{ fontSize: "2em" }}
                              duration={3.5}
                            />
                            <h4 className="iranSansBold">
                              {t("TotalCategories")}
                            </h4>
                          </Flex>
                        </div>
                      </Fade>
                      <Fade delay={700}>
                        <div className="bg-white shadow rounded col-md-4 col-12 m-3 p-2">
                          <Flex justify="center" align="center" is_column>
                            <img src={countryIcon} style={{ width: isMobile ? "15%" : "30%" }} />
                            <CountUp
                              separator={","}
                              className="iranSansBold text-dark  my-3"
                              start={0}
                              end={111}
                              style={{ fontSize: "2em" }}
                              duration={4}
                            />
                            <h4 className="iranSansBold">
                              {t("TotalCountry")}
                            </h4>
                          </Flex>
                        </div>
                      </Fade> */}
                    </div>
                  </Flex>
                </Col>
            
                )}
            
                <Col className="mt-5" md={6}>
                  <Slide left>
                    <Flex justify="center" align="center">
                      <img width={"100%"} src={dataAnalys} />
                    </Flex>
                  </Slide>
                </Col>
              </Row>
            </div>
          </Row>
        </Flex>
        <div className="bg-white mx-3 p-2 rounded-3 mt-5">
          <div className="row g-0 mt-3">
            <h3 className={`iranSansBold mt-2 mb-0`}>
              {t("WorldTopSuperApps")}
            </h3>
            <div className="row g-0 mt-4">
              <div className="col-md-12">
                {SuperAppsData.loading === true ? (
                  <Loading />
                ) : (
                  <Table
                    headers={TopSuperAppsHeaders}
                    rows={SuperAppsTableData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img style={{ height: "250px", width: "100%" }} src={waveImg2} /> */}
      <div id="Blog" className="text-white pb-5">
        <Row>
          <Col
            classNames="mt-5 m-3 d-flex flex-column justify-content-center align-items-center text-white "
            md={6}
            xs={12}
          >
            <Fade top>
              <p
                style={{ marginTop: "120px", fontSize: "1.4em" }}
                className="iranSansBold text-center mx-4"
              >
                {t("videoText")}
              </p>
            </Fade>
          </Col>
          <Col className="mt-5" md={6}>
            <Slide left>
              <Flex justify="center" align="center">
                {/* {
                  lang === "FA" ? ( */}
                    <div class="h_iframe-aparat_embed_frame">
                    <span style={{ display: "block" }}></span>
                    <iframe
                      className="video-player-ifame"
                      src={ lang === "FA" ? 
                          "https://www.aparat.com/video/video/embed/videohash/Enaui/vt/frame" 
                          :
                          "https://www.aparat.com/video/video/embed/videohash/1ahr4/vt/frame"
                        }
                      title="سوپراپلیکیشن، دستیار روزمره ما و چالش‌های آن"
                      allowFullScreen="true"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                    ></iframe>
                  </div>
                  {/* )  : (
                    <video src={"/Video/MainPage_EN.mp4"} className="w-75" controls />
                  )
                } */}
              </Flex>
            </Slide>
          </Col>
        </Row>
        {/* <div className="d-flex justify-content-center align-items-center mt-5">
                    <Link className="w-100 d-flex justify-content-center" to={'/blog'}>
                        <button className="btn btn-light rounded-pill w-25 mb-5">
                            مشاهده بیشتر
                        </button>
                    </Link>
                </div> */}
      </div>

      {/* MODALS */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <CountrySuperAppsModal
          country={openCountry}
          data={CountryAppsData}
          onClose={closeModal}
          TopSuperAppsHeaders={getTableHeaders(t)}
        />
      </Modal>
    </>
  );
}
