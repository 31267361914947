import { Flex } from 'Components'
import React from 'react'

export default function Title({text="", className}) {
    return (
        <Flex justify="center" align="center" is_column={true}>
            {/* <div className="line  my-2"></div> */}
            <h1 className={`iranSansBold text-center ${className}`}>{text}</h1>
        </Flex>
    )
}
