
import { useTranslation } from 'react-i18next';
import i18n  from "i18next"
import axios from "axios";
import Constants from "Constants";

import fa_IR from 'antd/lib/locale/fa_IR';
import en_US from 'antd/lib/locale/en_US';
import Swal from 'sweetalert2'

export const showToastAlert = (title="", type="success", position="top-start",  timer=3000) => {
    const Toast = Swal.mixin({
      toast: true,
      position,
      showConfirmButton: false,
      timer,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: type,
      title
    })
}

export const showAlert = (title="", text="", type="success", confirmButtonText="بازگشت") => {
  return Swal.fire({
    showClass: {
      popup: 'animate__animated animate__fadeInDown'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp'
    },
    icon: type,
    title,
    text,
    confirmButtonText,
  })
}

export const getDirection = () => {
    const rtlLangs = ['fa', 'ar']
    if (rtlLangs.includes(i18n.language) === true) {
        return "rtl"
    } else {
        return "ltr"
    }
};


export const getLocale = () => {
    switch (i18n.language) {
        case "fa":
            return fa_IR;
        
        case "en":
            return en_US;
            
        default:
            return fa_IR;
    }
};


const client = axios.create({
  baseURL: Constants.BASE_URL
});


export const API = function(route, method, body) {
  const onSuccess = function(response) {
    console.debug('Request Successful!', response);
    return response.data;
  }

  const onError = function(error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:',  error.response.status);
      console.error('Data:',    error.response.data);
      console.error('Headers:', error.response.headers);

    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  }

  return client({
    method,
    url: route,
    data: body
  })
            .then(onSuccess)
            .catch(onError);
}

class EasyRedux {
    constructor(name) {
      this.name = name;
  
      this.actionTypes = {
        resetCache: `${name}/RESET_CACHE`,
      };
  
      this.reducer = {};
  
      this.actions = {
        resetCache: () => ({ type: this.actionTypes.resetCache }),
      };
  
      this.initialState = {};
  
      this.generateActionsAndInitialState();
  
      this.generateReducer();
    }
  
    generateActionsAndInitialState = () => {
      this.actionTypes = {
        ...this.actionTypes,
        load: `${this.name}/LOAD`,
        loadSuccess: `${this.name}/LOAD_SUCCESS`,
        loadFailure: `${this.name}/LOAD_FAILURE`,
      };
  
      this.initialState = {
        loading: false,
        loaded: false,
        error: {},
        data: {},
        form: {},
      };
  
      const { load, loadSuccess, loadFailure } = this.actionTypes;
  
      this.actions = {
        ...this.actions,
        load: (payload = {}) => ({
          type: load,
          payload,
        }),
        loadSuccess: (payload = {}) => ({
          type: loadSuccess,
          payload,
        }),
        loadFailure: (payload = {}) => ({
          type: loadFailure,
          payload,
        }),
      };
    };
  
    generateReducer = () => {
      this.reducer = (state = this.initialState, action) => {
        switch (action.type) {
          case `${this.name}/LOAD`:
            return {
              ...state,
              loading: true,
              loaded: false,
              form: action.payload,
            };
          case `${this.name}/LOAD_SUCCESS`:
            return {
              ...state,
              loading: false,
              loaded: true,
              data: action.payload,
            };
          case `${this.name}/LOAD_FAILURE`:
            return {
              ...state,
              loading: false,
              loaded: true,
              error: action.payload,
            };
          case `${this.name}/RESET_CACHE`:
            return this.initialState;
          default:
            return state;
        }
      };
    };
}


export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default EasyRedux
  
