import React, { useEffect } from 'react';
import { BlogPost, Flex, Nav, Footer, Loading } from 'Components'
import { useDispatch, useSelector } from "react-redux";
import { actions as loadBlogPosts } from 'Redux/modules/Blog/getBlogPosts';

export default function Blog(props) {
  const BlogPosts = useSelector(state => state.getBlogPosts)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadBlogPosts.load())
  }, [])

  return (
    <>
    {
      BlogPosts.loading === true ? <Loading /> : (
        <div className="h-100 mb-5" id="Blog">
        <Flex justify="center" align="center" is_column>
          <h1 className="text-white text-center pt-5 iranSansBold">بلاگ رصدگر</h1>
          <p className="text-ceter text-white iranSans">اخرین مقالات و پست های بلاگ رصدگر را در این صفحه بخوانید!</p>
          <div className={`col-6 input-icons search position-relative mt-3`}>
            <i style={{ left: "3%" }} className="fa fa-search icon text-black-50"></i>
            <input style={{ backgroundColor: "#f1f3f5" }} placeholder={"جستجو..."} className="form-control rounded-pill border-0" />
          </div>
        </Flex>
        <div className=" p-3 ">
          <div className="row g-0 d-flex justify-content-around">
            {
              BlogPosts && BlogPosts.data && BlogPosts.data.length && BlogPosts.data.map((post, i) => {
                return (
                  <BlogPost
                    key={`post-${i}`} 
                    post={post}
                    show_body={true}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
     
      )
    }
    </>
  )
}
