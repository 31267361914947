import React from 'react'
import { Fade } from 'react-reveal';
import InputSlider from 'react-input-slider';
import { utils } from 'utils';


export default function Question({
    question = {},
    index = 1,
    onRespond,
}) {
    const [showText, setShowText] = React.useState(false);
    const [slider, setSlider] = React.useState({ x: 0, y: 0 });

    return (
        <>
        <Fade>
            <div className="col-md-6 col-12 bg-white shadow m-3 p-3 ">
                <p style={{ fontSize: "1.3em" }} className="iranSansBold">{index + 1}) {question.title}</p>
                <div style={{width: "100% !important"}} className="line m-2 mb-4"></div>
                {
                    // RADIO BUTTON FORM
                    question.type === "radio" ? (
                        question.answers && question.answers.map((answ, i) => {
                            return (
                                <p className="mb-2">
                                    <input
                                        name={`radio-${question.id}`}
                                        value={answ.value}
                                        type="radio"
                                        className=""
                                        onChange={(e) => {
                                            if (question.has_text === true) {
                                                setShowText(true);
                                            }
                                            onRespond(question, answ, index)
                                        }}
                                    />
                                    <label className="m-1" for={`radio-${question.title}`}>{answ.label}</label>
                                </p>
                            )

                        })
                        ) : question.type === "checkbox" ? (
                        question.answers && question.answers.map((answ, i) => {
                            return (
                                <p className="mb-2">
                                    <input
                                        name={`checkbox-${question.id}`}
                                        value={answ.value}
                                        type="checkbox"
                                        className=""
                                        onChange={(e) => {
                                            if (question.has_text === true) {
                                                setShowText(true);
                                            }
                                            onRespond(question, answ, index)
                                        }}
                                    />
                                    <label className="m-1" for={`radio-${question.title}`}>{answ.label}</label>
                                </p>
                            )
                        })

                    ) : question.type === "slider" ? (
                        <>
                        {/* RANGE SLIDER FORM */}
                            سن شما: <span style={{fontSize: "1.3em"}} className="iranSansBold">{slider.x}</span>                        
                            <InputSlider
                                className="w-100 LTR"
                                axis="x"
                                xmin={question.min}
                                xmax={question.max}
                                x={slider.x}
                                onChange={({ x }) => {
                                    if (x < question.min || x > question.max) {
                                        utils.showToastAlert(
                                            `عدد وارد شده باید  بین ${question.min} تا ${question.max} باشه!`,
                                            "error",
                                        )
                                    }  else {
                                        setSlider(state => ({ ...state, x }))
                                        onRespond(question, {value: x}, index)
                                    }
                                }}
                                tooltip={slider.x}
                            />
                        </>
                    ) : <></>
                } 
                {
                    showText === true ? (
                        <div className="mt-4">
                            <p style={{fontSize: ".8em"}}>توضیحات شما:</p>
                            <textarea onChange={(e) => {
                                  onRespond(question, null, null, true, e.target.value)
                            }} className="form-control" />
                        </div>
                    ) : <></>
                }
            </div>
        </Fade>
        </>
    )
}
