import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';

const fakeBlogPost = {
      title: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
      image: "https://www.iranweber.com/wp-content/uploads/2021/05/Web-1920-%E2%80%93-1-1024x576.png",
      body: '<p><em>this</em> is<span style="color:#e74c3c"> a <strong><em><u>test&nbsp;</u></em></strong></span></p>\r\n\r\n<pre>\r\n<strong><u><span style="background-color:#2ecc71">و این هم</span></u><em><u><span style="background-color:#2ecc71"> متن انگلیسی نیست ظاهرا</span></u></em></strong></pre>',
      likes: 12,
      seen: 158,
      author: "شایان حسینی",
      date: "12 تیر",
  
}
  


const api = '/api/apps/post-detial/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'BlogPostDetails'
);

export function* watchGetBlogPostDetail({ payload }) {
  try {

    const result  = yield call(utils.API, `${api}${payload.post_id}/`, "GET");
    yield put(actions.loadSuccess(result));
    // yield handleSagaSuccess('panel.alertModal.acceptRejectedRequest');
  } catch (e) {
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;