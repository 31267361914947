import {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import {utils} from "utils";
import appRoutes from "routes";
 
import {Loading, Scroller} from "Components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLang } from "Redux/modules/info";

function App() {
  
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    // const 
    axios.get("https://geolocation-db.com/json/").then(res => {
      setLoading(false);
      if (res?.data?.country_code === "IR") {
        dispatch(setLang("FA"));
        i18n.changeLanguage("fa");
      } else {
        dispatch(setLang("EN"));
        i18n.changeLanguage("en");
      }
    })
    .catch(err => {
      setLoading(false);
      dispatch(setLang("FA"));
      i18n.changeLanguage("fa");
    })
  }, [])


  if (loading === true) {
    return <Loading />
  }

  return (
    <div style={{direction: utils.getDirection()}} className="App">
      <Router>
        <Scroller />
        <Switch>
          {
            appRoutes.map((app_route, i) => {
              return <Route
                  key={"Route" + i}
                  path={app_route.path}
                  exact
                  render={(props) => {
                    return (
                      <app_route.layout {...props}>
                        <app_route.component {...props} />
                      </app_route.layout>
                    );
                  }}
                />
            })
          }
          <Route
            exact
            path="/admin"
            render={(props) => {
              return <Redirect to="/api/admin" />
            }} 
          />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
