import React from 'react'
import TimePieceIMG from "assets/images/gahShomar.jpg"

export default function TimePiece() {
    return (
        <>
            <img className="w-100" src={TimePieceIMG} />  
        </>
    )
}
