import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';

const blogPostsArr = [
  {
      title: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ",
      image: "https://www.iranweber.com/wp-content/uploads/2021/05/Web-1920-%E2%80%93-1-1024x576.png",
      body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
      likes: 12,
      seen: 158,
      author: "شایان حسینی",
      date: "12 تیر",
  
  },
  {
      title: "چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
      image: "https://www.androidtime.com/wp-content/uploads/2019/10/PayPal-icon.png",
      body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
      likes: 29,
      seen: 158,
      author: "روح‌الله مظفری",
      date: "12 تیر",
  
  },
  {
      title: "طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد",
      image: "https://www.logo.wine/a/logo/Instagram/Instagram-Glyph-White-Dark-Background-Logo.wine.svg",
      body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای ",
      likes: 56,
      seen: 187,
      author: "شایان حسینی",
      date: "12 تیر",
      
  },
  {
      title: "طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد",
      image: "https://www.finextra.com/finextra-images/top_pics/xl/dribbble-740x555.png",
      body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای ",
      likes: 56,
      seen: 187,
      author: "شایان حسینی",
      date: "12 تیر",

  },
  {
      title: "طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد",
      image: "https://www.baseinshanghai.com/bis_content/uploads/2019/11/Alipay-1.jpg",
      body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای ",
      likes: 56,
      seen: 187,
      author: "شایان حسینی",
      date: "12 تیر",
  },
  {
      title: "طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد",
      image: "https://agiletech.vn/wp-content/uploads/2020/02/Untitled-13.1-01.jpg",
      body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای ",
      likes: 56,
      seen: 187,
      author: "شایان حسینی",
      date: "12 تیر",
  },
]


const api = '/api/apps/posts/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'BlogPosts'
);

export function* watchGetBlogPost() {
  try {
    const result  = yield call(utils.API, api, "GET");
    yield put(actions.loadSuccess(result));
    // yield handleSagaSuccess('panel.alertModal.acceptRejectedRequest');
  } catch (e) {
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;