import React from 'react'
import {Container, Slider, Row, Col, Loading} from "Components";
import ReactHtmlParser from 'react-html-parser';
import { actions as  loadTwitterData } from 'Redux/modules/TwitterData';
import { useDispatch, useSelector } from 'react-redux';

import Slide_1 from "assets/images/TwitterSlider/Slide1.JPG";
import Slide_2 from "assets/images/TwitterSlider/Slide2.JPG";
import Slide_3 from "assets/images/TwitterSlider/Slide3.JPG";
import Slide_4 from "assets/images/TwitterSlider/Slide4.JPG";
import Slide_5 from "assets/images/TwitterSlider/Slide5.JPG";
import Slide_6 from "assets/images/TwitterSlider/Slide6.JPG";
import Slide_7 from "assets/images/TwitterSlider/Slide7.JPG";
import Slide_8 from "assets/images/TwitterSlider/Slide8.JPG";
import Slide_9 from "assets/images/TwitterSlider/Slide9.JPG";
import Slide_10 from "assets/images/TwitterSlider/Slide10.JPG";
import Slide_11 from "assets/images/TwitterSlider/Slide11.JPG";
import Slide_12 from "assets/images/TwitterSlider/Slide12.JPG";
import Slide_13 from "assets/images/TwitterSlider/Slide13.JPG";
import Slide_14 from "assets/images/TwitterSlider/Slide14.JPG";
import Slide_15 from "assets/images/TwitterSlider/Slide15.JPG";
import Slide_16 from "assets/images/TwitterSlider/Slide16.JPG";
import Slide_17 from "assets/images/TwitterSlider/Slide17.JPG";
import Slide_18 from "assets/images/TwitterSlider/Slide18.JPG";
import Slide_19 from "assets/images/TwitterSlider/Slide19.JPG";
import Slide_20 from "assets/images/TwitterSlider/Slide20.JPG";
import Slide_21 from "assets/images/TwitterSlider/Slide21.JPG";
import Slide_22 from "assets/images/TwitterSlider/Slide22.JPG";
import Slide_23 from "assets/images/TwitterSlider/Slide23.JPG";
import Slide_24 from "assets/images/TwitterSlider/Slide24.JPG";
import Slide_25 from "assets/images/TwitterSlider/Slide25.JPG";
import Slide_26 from "assets/images/TwitterSlider/Slide26.JPG";
import Slide_27 from "assets/images/TwitterSlider/Slide27.JPG";
import Slide_28 from "assets/images/TwitterSlider/Slide28.JPG";
import Slide_29 from "assets/images/TwitterSlider/Slide29.JPG";
import Slide_30 from "assets/images/TwitterSlider/Slide30.JPG";
import Slide_31 from "assets/images/TwitterSlider/Slide31.JPG";
import Slide_32 from "assets/images/TwitterSlider/Slide32.JPG";

const SliderImages = [
  Slide_1,
  Slide_2,
  Slide_3,
  Slide_4,
  Slide_5,
  Slide_6,
  Slide_7,
  Slide_8,
  Slide_9,
  Slide_10,
  Slide_11,
  Slide_12,
  Slide_13,
  Slide_14,
  Slide_15,
  Slide_16,
  Slide_17,
  Slide_18,
  Slide_19,
  Slide_20,
  Slide_21,
  Slide_22,
  Slide_23,
  Slide_24,
  Slide_25,
  Slide_26,
  Slide_27,
  Slide_28,
  Slide_29,
  Slide_30,
  Slide_31,
  Slide_32,
] 

export default function TwitterAnalysis() {
  const dispatch = useDispatch();
  const TwitterData = useSelector(state => state.TwitterData);

  React.useEffect(() => {
    dispatch(loadTwitterData.load());
  }, [])

  if (TwitterData.loading === true) {
    return <Loading />
  } else {
    return (
      <div className="mb-5">
        <Slider
          images={SliderImages} 
        />
        <Container>
          <Row>
            <Col className="bg-white mt-5 p-5 shadow blog-body" md={12}>
                {ReactHtmlParser(TwitterData.data.title)}


                {ReactHtmlParser(TwitterData.data.body)}

            </Col>
          </Row>
        </Container>
      </div>
    )
  }

}
