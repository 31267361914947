import React from 'react'
import {Container, Slider, Row, Col, Loading} from "Components";
import ReactHtmlParser from 'react-html-parser';
import { actions as  loadPoliticalAnalysis } from 'Redux/modules/PoliticalAnalysis';
import { useDispatch, useSelector } from 'react-redux';


export default function TwitterAnalysis() {
  const dispatch = useDispatch();
  const PoliticalAnalysisData = useSelector(state => state.PoliticalAnalysis);

  React.useEffect(() => {
    dispatch(loadPoliticalAnalysis.load());
  }, [])

  if (PoliticalAnalysisData.loading === true) {
    return <Loading />
  } else {
    return (
      <div className="mb-4">
        <Container>
          <Row>
            <Col className="bg-white mt-5 p-5 shadow blog-body" md={12}>
                {ReactHtmlParser(PoliticalAnalysisData.data.title)}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

}
