import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Loading, Container, Flex, Nav, Footer, Col, Row } from 'Components';
import { Fade, Slide } from 'react-reveal';

import { actions as loadSuperAppDetail } from 'Redux/modules/SuperApp/SuperAppDetail';
import Constants from 'Constants';
import { utils } from 'utils';
import ReactHtmlParser from 'react-html-parser';


export default function SuperAppDetail(props) {

  const superAppData = useSelector(state => state.SuperAppDetail)
  const dispatch = useDispatch()
  React.useEffect(() => {
    const superApp_id = props.match.params.id
    dispatch(loadSuperAppDetail.load({id: superApp_id}))
  }, [])

  console.log(superAppData)

  if (superAppData.loading === true) {
    return <Loading />
  } else {
    const appData = superAppData && superAppData.data
    return (
      <div className="mb-5">
        <Container >
          <Row className="g-5">
            <Col md={8}>
              <div className="bg-white shadow rounded mt-5 py-3">
                <Row>
                  <Col className="m-3" xs={3} md={1}>
                    <a href={appData.app_website || "#"} target="_blank">
                       <img className="w-100 rounded" src={`${Constants.BASE_URL}${appData.logo}`} />
                    </a>
                  </Col>
                  <Col className="mt-2" xs={7} md={4}>
                    <Fade>
                      <h1>{appData.name}</h1>
                    </Fade>
                    <h6 className="text-black-50"><i className="far fa-list-alt m-1"></i>{appData.core_asset} </h6>
                  </Col>
                  <Col className="mt-2 m-3 text-center" md={4}>
                    <Fade delay={500}>
                      <p className="text-black-50 mb-1">Monthly Active Users</p>
                    </Fade>
                    <Fade delay={700}>
                      <h4 className="iranSansBold text-success">{utils.numberWithCommas(appData.monthly_active_users || 0)}</h4>
                    </Fade>
                  </Col>
                  {/* <Col className="mt-2 text-center" md={2}>
                    <p className="text-black-50 mb-1">مدیرعامل</p>
                    <h6 className="iranSansBold">{appData.ceo || "-"}</h6>
                  </Col> */}
                </Row>
                  <Fade delay={900}>
                <Row className="d-flex justify-content-center mt-5">

                {
                   appData.main_features && appData.main_features.length && appData.main_features.map( (f,i) => {
                    return (
                        <Col key={i}  className="mt-2 m-3 " md={3} xs={6}>
                          <div style={{backgroundColor: ""}} className="d-flex justify-content-center align-items-lg-center">
                            <img style={{width: "20%"}} src={Constants.BASE_URL + f.icon} />
                            <h6 className="iranSansBold m-2" style={{fontSize: "1em"}}>{f.name}</h6>
                          </div>
                        </Col>

                    )
                  })
                }
                </Row>
                </Fade>
                <Row className="mt-2 m-5">
                  <Col>
                    {/* <p className="text-black-50 mb-1">درباره اسنپ</p> */}
                    <Slide right>
                      <p className="iranSansBold ck-post">
                        {ReactHtmlParser(appData.description)}
                      </p>
                    </Slide>
                  </Col>
  
                </Row>
              </div>
  
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12} xs={12}>
                <div style={{maxHeight: "255px", overflowY: "scroll"}} className="bg-white shadow mt-5 p-3">
                    <h3 className="iranSansBold text-start"> Other Countries ({appData.other_countries && appData.other_countries.length}) <i className="fa fa-globe"></i></h3>
                    <div className="">
                      {
                        appData.other_countries && appData.other_countries.map((country,i) => {
                          return (
                            <Col md={12}>
                              <h6 className="iranSansBold text-start">
                                {country.name}
                                <i className="fa fa-flag m-2"></i>
                              </h6>
                            </Col>
                          )
                        })
                      }
                      {appData.other_countries && appData.other_countries.length === 0 ? 
                        <h6 className="text-black-50 text-center mt-5">---</h6>
                      : <></>
                      }
                    </div>
                </div>
                    </Col>
                </Row>
                <Row>
                <Col md={12} xs={12}>
                <div className="bg-white shadow mt-5 p-3">
                    <h3 className="iranSansBold text-start">Resources <i className="fa fa-newspaper"></i></h3>
                    <div className="line mb-3"></div>
                    <div className="text-start d-flex flex-column mt-2">

                      <a className="mt-2" href={appData.Refference_of_MAU_and_other_user_statistics || "#"}>
                        
                        Refference of MAU and other user statistics
                        </a>
  
                      <a className="mt-2" href={appData.Refference_of_Value_and_other_financial_Stats || "#"}>
                        Refference of Value and other financial Stats
                        
                        </a>
  
                      <a className="mt-2" href={appData.Refferences_of_other_info || "#" }>
                        Refferences of other info
                        
                        </a>


                    </div>
                  </div>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

}
