import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as loadBlogPostDeatil } from 'Redux/modules/Blog/getBlogPostDetail';
import { Loading, Container, Flex, Nav, Footer } from 'Components';
import ReactHtmlParser from 'react-html-parser';
import Constants from 'Constants';
import moment from 'moment-jalaali';

export default function BlogDetail(props) {
  const dispath = useDispatch();
  const postDetails = useSelector(state => state.getBlogPostDetails)
  const post_id = props.match.params.id;

  useEffect(() => {
    dispath(loadBlogPostDeatil.load({ post_id }))
  }, [])

  console.log(postDetails)

  if (postDetails.loaded === false) {
    return <Loading />
  } else {
    return (
      <>
        <div id="Blog" className=" py-5">

          {postDetails.data && (
            <Container>
              <Flex is_column>
                <div className="bg-white mt-4 shadow rounded w-75 my-5">
                  <h3 className="iranSansBold text-center text-dark my-5">{postDetails.data.title}</h3>

                  <Flex justify="between">
                    <div className="text-black-50">
                      <i className="fa fa-user m-2"></i>
                      {postDetails.data.author}
                      <div>
                        <i className="fa fa-calendar m-2"></i>
                        {moment(postDetails.data.created_time).format("jYYYY/jMM/jDD")}
                      </div>
                    </div>
                    <div style={{ fontSize: ".8em" }} className="m-3 text-black-50">
                      <i className="fa fa-clock m-2"></i>
                      {postDetails.data.read_time}
                      <div>
                        <i className="fa fa-eye m-2"></i>
                        {postDetails.data.views_number || 0} بازدید
                      </div>
                    </div>
                  </Flex>
                </div>

                <img style={{ height: "auto" }} className="w-75" src={`${Constants.BASE_URL}${postDetails.data.image_url}`} />
                <div className="blog-body shadow bg-white mt-5 rounded p-3 w-75">
                  {ReactHtmlParser(postDetails.data.body)}
                </div>
              </Flex>
            </Container>
          )}
        </div>
      </>
    )
  }

}
