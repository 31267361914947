import { combineReducers } from 'redux';

import getBlogPosts from "./modules/Blog/getBlogPosts";
import getBlogPostDetails from "./modules/Blog/getBlogPostDetail";
import AboutUs from "./modules/AboutUs";
import MapData from "./modules/Map";
import CountryAppsModal from "./modules/Map/CountryAppsModal";
import Counters from "./modules/Counters";
import SuperAppDetail from "./modules/SuperApp/SuperAppDetail";
import SuperApps from "./modules/SuperApp/SuperApp";
import TwitterData from "./modules/TwitterData";
import PoliticalAnalysis from "./modules/PoliticalAnalysis";
import SurveyResponse from "./modules/Survey";
import Info from "./modules/info/index";

export default combineReducers({
  getBlogPosts,
  getBlogPostDetails,
  AboutUs,
  MapData,
  CountryAppsModal,
  Counters,
  SuperAppDetail,
  SuperApps,
  TwitterData,
  PoliticalAnalysis,
  SurveyResponse,
  Info
})