import React from 'react'
import {Nav, Footer} from "Components";
import { Fade, Slide } from 'react-reveal';


export default function AppLayout({children}) {
  return (
    <>
      <Fade top >
        <Nav curve={false}/>
      </Fade>
        {children}
        <Fade bottom>
          <Footer />
        </Fade>
    </>
  )
}
