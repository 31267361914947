import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';
  

const route = 'api/apps/twitter-data-analysis/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'TwitterData'
);

export function* watchLoadTwitterData({ payload }) {
    console.log("AA")
  try {
    let result  = yield call(utils.API, route, "GET");   
    console.log(result) 
    yield put(actions.loadSuccess(result[0]));

  } catch (e) {
    console.error(e)
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;