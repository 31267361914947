import {Table, Flex, Loading} from 'Components'
import Constants from 'Constants';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { utils } from 'utils';


export default function CountrySuperAppsModal({
    country,
    onClose,
    data,
    TopSuperAppsHeaders=[]
}) {
    const {t} = useTranslation();
    const {lang} = useSelector(state => state.Info) 

    if (data.loading === true) {
        return <Loading />
    } else {
        return (
          <div className="bg-white">
            <h1 className="text-center"> {t("CountrySuperApps")} {country}</h1>
            <div className="mt-5">
                {data.loading === true ? <Loading /> : (
                    <Table 
                      headers={TopSuperAppsHeaders}
                      rows={data.data}
                      minWidth={"500px"}
                      />
                )}
            </div>
      
              <Flex align="center" justify="center">
                <button onClick={() => {
                  onClose()
                  }} className="btn btn-danger">بازگشت</button>
              </Flex>
          </div>
        )
    }
}
