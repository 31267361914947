import EasyRedux, {utils, history } from "utils";
import { put, call } from 'redux-saga/effects';
  
const route = '/api/apps/answer-create/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'Survey'
);

export function* watchSendSurveyData({ payload }) {
  
  try {
    const result  = yield call(utils.API, route, "POST", payload);
    yield put(actions.loadSuccess(result));
    utils.showAlert(
        "با تشکر از همکاری شما",
        "اطلاعات وارد شده با موفقیت ثبت شد",
        "success",
        "بازگشت به خانه"
    )
    
  } catch (e) {
    console.error(e)
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;