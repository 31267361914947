import EasyRedux, {utils } from "utils";
import { put, call } from 'redux-saga/effects';
  

const route = '/api/apps/total-apps/';

export const { actionTypes, actions, reducer } = new EasyRedux(
  'Counters'
);

export function* watchGetCounters({ payload }) {
  
  try {
    const result  = yield call(utils.API, route, "GET");
    const data = {
      apps: result['number of apps'],
      users: result['number of users'],
    }
    yield put(actions.loadSuccess(data));
    
  } catch (e) {
    console.error(e)
    yield put(actions.loadFailure(e));
    // yield handleSagaError(e);
  }
}

export default reducer;